import React from 'react'
import Layout from '../layouts'
import { graphql } from 'gatsby'
// import ShopCategory from "../components/ecwid/category"

class CustomTeakNamePage extends React.Component {
	componentDidMount() {
		if (window) {
			const script = document.createElement('script')
			script.type = 'text/javascript'
			script.src = `https://forms.webriq.com/js/initForms`
			const headScript = document.getElementsByTagName('script')[0]
			headScript.parentNode.insertBefore(script, headScript)
		}
	}
	render() {
		let params = this.props.location.pathname
		return (
			<Layout
				pathname={params}
				head={{
					name: 'Buck Woodcraft Custom Teak Name Board -- Price Quote Request',
					meta: {
						description:
							'Buck Woodcraft Custom Teak Name Board -- Price Quote Request Please use this form to request price & shipping quotes for full uncut sheets of King Starboard. ',
					},
				}}
			>
				<div className="container py-5">
					<div className="row">
						{/*<div className="col-md-4">
							<h2 className="section-heading">Categories</h2>
							<ShopCategory/>
						</div>*/}
						<div className="col-md-8 shop-items mx-auto">
							<div className="page-content">
								<h4 className="section-heading page-mt">
									Buck Woodcraft Custom Teak Name Board -- Price Quote Request
								</h4>
								<p>
									Please use this form to request price & shipping quotes for
									full uncut sheets of King Starboard. If you also need
									half-sheet quantities, we can add those to your full-sheet
									shipment. Simply describe your requiremens where indicated
									below.
								</p>
								<p>
									Shipments to a Florida address will be subject to sales tax.
									Truck freight shipments to a residence, walk-in retail shop,
									many marinas, dr's offices etc. are higher than if shipped to
									a business with a loading dock and/or lift truck.
								</p>
								<form
									action="/thank-you"
									method="post"
									data-form-id="5c4edbfc8ba9a30a93a29fff"
									webriq="true"
								>
									<div className="form-group">
										<label>Your Name:</label>
										<input
											className="form-control"
											type="text"
											name="name"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Your Email Address: *</label>
										<input
											className="form-control"
											type="text"
											name="email"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>Contact Phone Number</label>
										<input
											className="form-control"
											type="text"
											name="phone-number"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>How Many Boards Of the same design*</label>
										<br />
										<input
											type="checkbox"
											value="One Name Board"
											name="one-name-board"
										/>{' '}
										One Name Board <br />
										<input
											type="checkbox"
											value="Two Name Board"
											name="two-name-board"
										/>{' '}
										Two Name Board <br />
										<input type="checkbox" value="Other" name="others" /> Other
										<br />
									</div>
									<div className="form-group">
										<label>
											Preferred Name Board Dimensions And/Or Letter Height*
										</label>
										<input
											className="form-control"
											type="text"
											name="preferred-name-board"
											required=""
										/>
									</div>
									<div className="form-group">
										<label>
											Name Board Content (Complete vessel name including any
											spaces, preferred type font if any, preferred name board
											profile, preferred nameboard art)
										</label>
										<textarea
											className="form-control"
											rows="10"
											name="name-board-content"
										/>
									</div>
									<div className="form-group">
										<label>Additional Information And/Or Questions</label>
										<textarea
											className="form-control"
											rows="10"
											name="additional-information"
										/>
									</div>
									<div className="webriq-recaptcha mb-3" />
									<div className="form-group">
										<button
											className="btn btn-primary btn-block blue-btn"
											type="submit"
										>
											Submit
										</button>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</Layout>
		)
	}
}

export default CustomTeakNamePage

export const categorynodeQuery = graphql`
	query {
		allStrapiCategories(sort: { fields: id, order: ASC }) {
			edges {
				node {
					id
					name
					url
					subcategories {
						name
						url
					}
				}
			}
		}
	}
`
